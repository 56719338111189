<template>
  <section id="goods" class="goods">
    <!--  -->
    <div class="container">
      
      <carousel :perPageCustom="[[360, 3], [800, 4]]"
                :mouse-drag="true"
                :navigationEnabled="true"
                :paginationEnabled="false">
          <!-- картинки -->
          <slide
            v-for="(item, index) in list"
            :data-index="index"
            :key="'good_id_' + index">

            <ListCard :goodItem="item"/>            

          </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import ListCard from './ListCard'
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from "vuex";

export default {
  name: "Goods",
  components: {
    Carousel,
    Slide,
    ListCard
  },
  computed: {
    ...mapGetters('goods', ['list'])
  },
  data() {
    return {    
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.goods {
  margin-top: -5%;
  margin-bottom: 5%;
  position: relative;
  z-index: 1000;
  padding-bottom: 3%;
  
  -webkit-box-shadow: 0px -5px 5px -5px rgba(169, 169, 169, 0.6) inset;
  -moz-box-shadow: 0px -5px 5px -5px rgba(169, 169, 169, 0.6) inset;
  box-shadow: 0px -5px 5px -5px rgba(169, 169, 169, 0.6) inset;

  &__img {
    max-height: 8rem;
  }  
}

@media (max-width: 420px) {
  .goods {
    margin-top: -20%;
    margin-bottom: 15%;
    position: relative;
    z-index: 1000;

    &__img {
      max-height: 7rem;
    }
  }
}
</style>
