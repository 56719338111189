<template>
  <section id="faq" class="faq">
    <!--  -->
    <div class="container">
      <h3>{{ $t("blocks.faq.title") }}</h3>
      <b-row>
        <b-col>
          <!-- строки -->
          <FaqItem
            v-for="(item, index) in faqItems"
            :key="'feature_id_' + index"
            :title="item.title"
            :description="item.description"
          />
        </b-col>

        <!-- картинка -->
        <b-col class="flex_col_center_center">
          <b-img class="faq__img" center src="/images/faq-img.svg" />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import FaqItem from "./FaqItem";
import i18n from "@/i18n";

export default {
  name: "Faq",
  components: {
    FaqItem,
  },
  computed: {
    faqItems() {
      let fItems = [];
      for (let index = 0; index < 4; index++) {
        fItems.push({
          title: i18n.t("blocks.faq.items[" + index + "].title"),
          description: i18n.t("blocks.faq.items[" + index + "].description"),
        });
      }
      return fItems;
    },
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.faq {
  padding-top: 2rem;
  padding-bottom: 5%;

  &__img {
    max-width: 350px;
  }
}
</style>
