<template>
  <section id="additionalDescription" class="additionalDescription container">

    <b-row class="additionalDescription__title">
      <b-col>
        <h5 class="">Оплачивайте зарубежные сервисы в 2023 году без проблем</h5>
        <p>Оплата зарубежных сервисов без иностранных карт, без регистрации и скрытых комиссий. Вы можете оплатить более 100 сервисов и сайтов из России с российских карт. Вы можете купить или продлить подписку на Zoom, PS, Microsoft, Freepik, Netflix, Spotify, YouTube, Google и другие. Вы можете купить игру на Steam и EA, пополнить игровой кошелек аккаунта для покупки скинов, игровых валют и т.д. Мы помогаем не только купить подписку Зум, Плейстейшн и др., но и готовы помочь с более 100 различных зарубежных сервисов и приложений.</p>
      </b-col>
    </b-row>
    
  </section>
</template>

<script>


export default {
  name: "AdditionalDescription",
  components: { 
      
   },
  computed: {
    
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

.additionalDescription{

  margin-top: 3rem;

   &__title{
    margin-bottom: 2rem;
  }

  &__step{
    margin-bottom: 1.5rem;
  }
 
 &__step_number{
  font-size: 4rem;
  padding-bottom: 0px;
 }
}

</style>
