<template>
  <div class="">

    <!-- Заголовок -->
    <HeroSection/>
      
    <!--Каталог товаров -->
    <Goods/>

    <!--Как это работает -->
    <HowItWorks/>

    <!-- Отзывы -->
    <Feedback/>

    <!--
    <AdditionalDescription/>
    -->

    <Faq/>

    <GetContact/> 

  </div>
</template>

<script>
import HeroSection from './HeroSection'
import Goods from './goods/Goods'
import HowItWorks from './HowItWorks'
import Feedback from './Feedback'
import Faq from './faq/Faq'
import GetContact from './GetContact'

import AdditionalDescription from './AdditionalDescription'

export default {
  name: "land",
  components: { 
      HeroSection,
      Goods,
      HowItWorks,
      Feedback,
      AdditionalDescription,
      Faq,
      GetContact
   },
  computed: {
    
  },
  data() {
    return {
      startImg: require("@/assets/img/loginimg.png"),
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

</style>
