<template>
  <b-card class="mr-2 mb-2 shadow-sm"
    :img-src="goodItem.img"
    img-alt="Image"
    img-top>


    <b-card-text>
      
      <p class="good__title">{{ goodItem.name }}</p>
      <p class="good__description">{{ goodItem.description }}</p>
      
    </b-card-text>
    <div class="flex_col_center_center" v-if="!onlyCard">
      <b-button variant="primary"
        :to="{name:'GoodsItem', params: { id: goodItem.id }}">
        
        {{ goodItem.price + ' ₽' }}
      </b-button>
      

    </div>
    
    
  </b-card>
</template>

<script>

export default {
  name: "GoodListCard",
  components: {
  },  
  props: {
    goodItem: null,
    onlyCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {    
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.good {
  

  &__title {
    font-weight: 500;
  } 
  &__description {
    font-size: x-large;
    font-weight: 600;
  } 
}
</style>
