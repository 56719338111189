<template>
  <!-- Заголовок -->
  <section id="home" class="hero-section"
    :style="{ backgroundImage: `url(${bkgrImg})` }">
    <div class="container ">
      <b-row>
        <b-col lg="7">
          <h1>
            {{ $t("blocks.hero.Title") }}
          </h1>
          <p>{{ $t("blocks.hero.sub_title") }}</p>

          <div class="my-4 flex_start_center">            
            
            <b-button pill variant="light" size="lg" href="#goods"
              class="mb-1 mr-2">
            <span class="text-primary">{{ $t("blocks.hero.cta_button") }}</span> 
            </b-button>            
            
            <div class="d-none d-lg-block">
              <b-button pill variant="outline-light" size="lg" href="#joincontacts"
                    class=" mb-1 ">
                  <span class="">{{ $t("blocks.hero.call_button") }}</span> 
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col lg="5" class="flex_col_center_center d-none d-lg-block">
          <b-img class="hero-section__img"   src="/images/hero_img.png" />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
  components: {},
  computed: {},
  data() {
    return {
      bkgrImg: require("@/assets/img/hero/hero-bg.svg")
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.hero-section{
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  padding: 150px 0;
  color: #fff;

  h1 {
    font-size: 3em;
    margin-bottom: 35px;    
  }
  @media (max-width: 420px) {
  h1 {
    font-size: 1.8em;
  } 
 
}
&__img{
    max-width: 100%;
    height: auto;
    margin-right: -2.5rem;
  }
  @media (max-width: 420px) {
  &__img {
    max-width: 50%;
    height: auto;
    margin-right: 0rem;
    padding-top: 10%;
  }
  }
}


</style>
