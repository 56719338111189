<template>
  <section id="howitworks" class="howitWorks container">

    <b-row class="howitWorks__title">
      <b-col>
        <h2 class="flex_col_center_center mb-4">{{ $t("blocks.how_it_works.Title") }}</h2>
      </b-col>
    </b-row>
    
    <b-row>
      <!-- картинко -->
      <b-col md="2" />

      <!-- steps -->
      <b-col md="10">
        
        <b-row class="howitWorks__step">
          <b-col cols="2">
            <p class="howitWorks__step_number text-primary">1.</p>
          </b-col>
          <b-col cols="10">          
            <h3 class="text-primary">{{ $t("blocks.how_it_works.steps[0].title") }}</h3>
            <p v-html="$t('blocks.how_it_works.steps[0].text')"></p>            
          </b-col>
        </b-row>

        <b-row class="howitWorks__step">
          <b-col cols="2">
            <p class="howitWorks__step_number text-primary">2.</p>
          </b-col>
          <b-col cols="10">          
            <h3 class="text-primary">{{ $t("blocks.how_it_works.steps[1].title") }}</h3>
            <p v-html="$t('blocks.how_it_works.steps[1].text')"></p>            
          </b-col>
        </b-row>

        <b-row class="howitWorks__step">
          <b-col cols="2">
            <p class="howitWorks__step_number text-primary">3.</p>
          </b-col>
          <b-col cols="10">          
            <h3 class="text-primary">{{ $t("blocks.how_it_works.steps[2].title") }}</h3>
            <p v-html="$t('blocks.how_it_works.steps[2].text')"></p>            
          </b-col>
        </b-row>

      </b-col>
    </b-row>
       
       
    <b-row class="howitWorks__cta_button" >
      <b-col class="flex_col_center_center">
        <b-button pill variant="primary" size="lg" href="#goods" >
          <span>{{ $t("blocks.how_it_works.cta_button") }}</span> 
        </b-button>

      </b-col>
    </b-row>
    
  </section>
</template>

<script>


export default {
  name: "HowItWorks",
  components: { 
      
   },
  computed: {
    
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

.howitWorks{

  &__title{
    margin-bottom: 2rem;
  }

  &__cta_button{
    margin-bottom: 2rem;
  }

  &__step{
    margin-bottom: 1.5rem;
  }
 
 &__step_number{
  font-size: 4rem;
  padding-bottom: 0px;
 }
}

</style>
