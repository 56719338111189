<template>
  <section id="feedback" class="feedback shadow ">

    <!-- features -->
    <div class="container">     

      <h2 class="flex_col_center_center mb-4">Что о нас говорят люди?</h2>

      <b-row>
        <b-col md="6" class="text-center feedback__item"> 
          <b-img class="feedback__img" center src="/images/feedback/f3.png"/>
        </b-col>
        <b-col md="6" class="text-center feedback__item mb-2"> 
          <b-img class="feedback__img" center src="/images/feedback/f7.png"/>
        </b-col>   
      <b-row>
      </b-row>   
        <b-col md="6" class="text-center feedback__item"> 
          <b-img class="feedback__img" center src="/images/feedback/f6.png"/>
        </b-col>
        <b-col md="6" class="text-center feedback__item"> 
          <b-img class="feedback__img" center src="/images/feedback/f5.png"/>
        </b-col>
      </b-row>     
      
      <!--
      <div class="flex_col_center_center my-4">
         <b-button variant="outline-primary" pill :href="$t('brand.FeedbackUrl')"  target="_blank"> Читать отзывы</b-button>
      </div>
      -->
     

    </div>
  </section>
</template>

<script>


export default {
  name: "TechFeatures",
  components: { 
      
   },
  computed: {
    
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

.feedback{

  background-color: gainsboro;
  padding: 3rem 3rem 3rem 3rem;  
  @media (max-width: 420px) { 
    padding: 2rem 0.5rem 0.5rem 0.5rem;
  }
    
    @media (max-width: 420px) {
      &__item{  
        padding: 1rem;
      }
    }    
 

}

</style>
